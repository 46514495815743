import React from "react";
import { Image } from "react-bootstrap";
import BannerMobile from "../components/BannerMobile";
import BannerSebas from "../components/BannerSebas";
import Experiencia from "../components/Experiencia";
import ExperienciaMobile from "../components/ExperienciaMobile";
import Footer from "../components/Footer";
import Frase from "../components/Frase";
import Galeria from "../components/Galeria";
import Marcas from "../components/Marcas";
import MarcasMobile from "../components/MarcasMobile";
import NavReact from "../components/NavReact";
import Proposito from "../components/Proposito";
import Servicios from "../components/Servicios";
import ServiciosMobile from "../components/ServiciosMobile";
import Testimonios from "../components/Testimonios";
import Video from "../components/Video";
import puntosazules from "../assets/puntosazules.png";
import GaleriaMobile from "../components/GaleriaMobile";
import PropositoMobile from "../components/PropositoMobile";
import TestimoniosMobile from "../components/TestimoniosMobile";

export default function Home() {
  return (
    <div>
      <NavReact />
      <div id="experiencia">
        <div className="d-none d-sm-block">
          <BannerSebas />
          <Experiencia />
        </div>
        <div className="d-sm-none d-block">
          <BannerMobile />
          <ExperienciaMobile />
        </div>
      </div>
      <Frase />
      <div className="d-none d-lg-block">
        <Marcas />
      </div>
      <div className="d-lg-none d-block">
        <MarcasMobile />
      </div>
      <Video />
      <div id="servicios">
        <div className="d-none d-xl-block">
          <Servicios />
        </div>
        <div className="d-xl-none d-block">
          <ServiciosMobile />
          <div>
            <Image
              className="puntos-azules "
              src={puntosazules}
              alt="puntos azules"
            />
          </div>
        </div>
      </div>
      <div className="d-none d-sm-block">
        <Galeria />
      </div>
      <div className="d-sm-none d-block">
        <GaleriaMobile />
      </div>
      <div id="proposito">
        <div className="d-none d-lg-block">
          <Proposito />
        </div>
        <div className="d-lg-none d-block">
          <PropositoMobile />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <Testimonios />
      </div>
      <div className="d-lg-none d-block">
        <TestimoniosMobile />
      </div>

      <Footer />
    </div>
  );
}
