import React from "react";
import { Image } from "react-bootstrap";
import BtnContacto from "./BtnContacto";
import puntosazules from "../assets/puntosazules.png";
import ReactPlayer from "react-player";

export default function Video() {
  return (
    <div className="container mt-4 pt-4 px-4">
      <div className="d-flex justify-content-start">
        <p className="peso-800-lato texto-size-18px color-celeste">
          {" "}
          MI PROPÓSITO
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="d-none d-sm-block">
            <p className="peso-400-redrose  texto-size-38px">
              {" "}
              Acercar mis ideas, capacidades <br /> y experiencia
            </p>
            <p className="peso-500-montserrat texto-size-18px">
              A personas y equipos de trabajo para que puedan alcanzar sus{" "}
              <br /> metas personales y comerciales mejorando su bienestar
              mental, <br /> emocional y profesional.
            </p>
            <BtnContacto />
          </div>
          <div className="d-sm-none d-block">
            <p className="peso-400-redrose  texto-size-24px">
              {" "}
              Acercar mis ideas, capacidades <br /> y experiencia
            </p>
            <p className="peso-500-montserrat texto-size-16px">
              A personas y equipos de trabajo para que puedan alcanzar sus metas{" "}
              <br />
              personales y comerciales mejorando su bienestar mental, emocional
              y <br />
              profesional.
            </p>
            <div className="text-center pb-4">
              <BtnContacto />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="video-rotacion  py-2">
            <ReactPlayer
              className=""
              url="https://www.youtube.com/watch?v=ovZoAOdp3ak"
              playing={false}
              muted
              controls={true}
              width="100%"
              height="350px"
            />
          </div>
          <div className="d-flex mt-2 py-2 justify-content-center">
            <p className="peso-400-redrose texto-size-30px me-2 ">
              Activación Destacada
            </p>{" "}
            <Image
              className="puntos-azules "
              src={puntosazules}
              alt="puntos azules"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
