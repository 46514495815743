import React from "react";
import { Image } from "react-bootstrap";
import frase from "../assets/frase.png";
import firmamobile from "../assets/firmamobile.png";

export default function Frase() {
  return (
    <div className="mt-4 pt-4">
      <div className="d-none d-lg-block">
        <Image
          className=""
          src={frase}
          alt="No nos recuerdan por lo que dijimos o quisimos decir... lo hacen por como los hicimos sentir"
          fluid
        />
      </div>
      <div className="d-lg-none d-block ">
        <div className="bg-firma-mobile">
          <div className="text-center">
            <p className="peso-400-redrose texto-size-20px">
              “No nos recuerdan por lo que <br /> dijimos o quisimos decir…{" "}
              <br />
              lo hacen por cómo los hicimos <br /> sentir”
            </p>
          </div>
          <div className="text-center py-4">
            <Image
              className=""
              src={firmamobile}
              alt="No nos recuerdan por lo que dijimos o quisimos decir... lo hacen por como los hicimos sentir"
              fluid
            />
          </div>
        </div>
      </div>
    </div>
  );
}
