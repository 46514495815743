import React from "react";
import { Image } from "react-bootstrap";
import bannermobile from "../assets/titulobannermobile.png";
import fotosbannermobile from "../assets/sebasportadamobile.png";

export default function BannerMobile() {
  return (
    <div className="container">
      <div className="text-center pt-4">
        <Image
          className=""
          src={bannermobile}
          alt="No nos recuerdan por lo que dijimos o quisimos decir... lo hacen por como los hicimos sentir"
          fluid
        />
      </div>
      <div className="container py-4">
        <div className="row">
          <div className="col-7">
            <p className="peso-400-redrose texto-size-16px">
              High impact marketing and entrenament + Personal Business Mentor
            </p>
            <p className="peso-400-redrose texto-size-14px">
              @sebaspinelli.trainer
            </p>
          </div>
          <div className="col-5">
            <Image
              className=""
              src={fotosbannermobile}
              alt="No nos recuerdan por lo que dijimos o quisimos decir... lo hacen por como los hicimos sentir"
              fluid
            />
          </div>
        </div>
      </div>
    </div>
  );
}
