import React from "react";
import BtnContacto from "./BtnContacto";

export default function ExperienciaMobile() {
  return (
    <div className="container  pt-4 px-4">
      <p className="peso-800-lato color-celeste texto-size-18px">
        {" "}
        MI EXPERIENCIA
      </p>
      <div>
        <p className="peso-400-redrose texto-size-24px">
          {" "}
          Especializado en resolver <br /> conflictos y generar <br />{" "}
          experiencias de marketing de <br /> alto impacto
        </p>
      </div>
      <div>
        <p className="peso-500-monserrat text-size-16px ">
          Constructor publicitario realizador de experiencias de marketing y
          entretenimiento de alto impacto, con más de 25 años creando y
          construyendo activaciones promocionales a pequeña y gran escala.
          Master en Psicología aplicada a la persuasión y resolución de
          conflictos personales y comerciales.
        </p>
      </div>
      <div className="text-center pt-2 ">
        <BtnContacto />
      </div>
    </div>
  );
}
