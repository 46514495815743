import React from "react";
import { Image } from "react-bootstrap";
import foto1 from "../assets/testimonio1.png";
import foto2 from "../assets/testimonio2.png";
import foto3 from "../assets/testimonio3.png";

export default function Testimonios() {
  return (
    <div className="container">
      <div>
        <div className="text-center mt-2 ">
          <p className="peso-800-lato texto-size-18px me-4 color-celeste">
            TESTIMONIALES
          </p>
        </div>
        <div className=" py-0  d-flex flex-wrap container">
          {/* #1 */}
          <div className="cards-resena px-4 py-2 my-5 mx-1 text-center d-flex flex-column justify-content-between">
            <p className="mb-0 text-black py-3 peso-500-montserrat texto-size-18px">
              En el proceso de acompañamiento que pude compartir con Sebastián,
              descubrí su solvencia y experiencia en el mundo de las
              organizaciones, sin dudas es un aporte para ampliar la mirada
              comercial mixeando innovación, soluciones creativas y pragmatismo.
              Gran poder de motivación, para poder potenciar a equipos de
              trabajo basado en su experiencia, sus técnicas y herramientas no
              convencionales.
            </p>
            <div className="py-5 text-black">
              <Image className="mb-3 mt-3" src={foto1} alt="foto perfil" />
              <p className="mb-0  text-nowrap  peso-800-lato texto-size-18px color-celeste ">
                Fernando Hindi
              </p>
              <p className="mb-0  text-nowrap peso-400-montserrat texto-size-16px">
                Presidente de la Federación Internacional <br /> de Coaching
                Ontológico Profesional
              </p>
            </div>
          </div>
          {/* #2 */}
          <div className="cards-resena px-4 py-2 my-5 mx-auto text-center d-flex flex-column justify-content-between">
            <p className="mb-0 text-black py-3 peso-500-montserrat texto-size-18px">
              Hace más de 10 años que venimos trabajando con Sebastián para
              diferentes proyectos vinculados a las fechas más importantes de
              nuestro calendario. Decoraciones Navideñas, vacaciones de
              invierno, día del niño, como así también potenciado asociaciones
              comerciales que hemos logrado con productoras de nivel
              internacional: Cirque Du Soleil, Lollapalooza, Rolling Stones,
              entre otras . En cada caso, hemos recibido una solución creativa e
              innovadora para cada necesidad, su nivel profesional, compromiso,
              entusiasmo y capacidad, hace que sigamos contando con el para
              encarar nuevos desafíos.
            </p>
            <div className="py-5 text-black">
              <Image className="mb-3 mt-3" src={foto2} alt="foto perfil" />
              <p className="mb-0  text-nowrap  peso-800-lato texto-size-18px color-celeste ">
                Magdalena Costa Naum
              </p>
              <p className="mb-0  text-nowrap peso-400-montserrat texto-size-16px">
                Gerente de Marketing <br /> CENTROS COMERCIALES CENCOSUD
              </p>
            </div>
          </div>
          {/* #3 */}
          <div className="cards-resena px-4 py-2 my-5 mx-auto text-center d-flex flex-column justify-content-between">
            <p className="mb-0 text-black py-3 peso-500-montserrat texto-size-18px">
              Contrate a Sebastián para un proyecto muy ambicioso para el
              producto Cartier Parfums ya que el lanzamiento exigía starndares
              internacionales. Quedamos encantados con el, por su gran capacidad
              resolutiva, por su creatividad, compromiso y nivel profesional.
            </p>
            <div className="py-5 text-black">
              <Image className="mb-3 mt-3" src={foto3} alt="foto perfil" />
              <p className="mb-0  text-nowrap  peso-800-lato texto-size-18px color-celeste ">
                Claudia Gabriela Fernández
              </p>
              <p className="mb-0  text-nowrap peso-400-montserrat texto-size-16px">
                Fragrances Brand Manager at Greta S.A.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
