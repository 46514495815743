import React from "react";
import { Image, Nav } from "react-bootstrap";
import logofooter from "../assets/logofooter.png";
import icono1 from "../assets/email.png";
import icono2 from "../assets/telefono.png";
import icono3 from "../assets/ig.png";
import icono4 from "../assets/in.png";

export default function Footer() {
  return (
    <div className="container" id="footer">
      <div className="px-4 pt-5 mt-4 ">
        <div className="row">
          {/* Column1 */}
          <div className="col-12 col-lg-4 text-md-start d-flex justify-content-lg-start justify-content-center mx-auto mt-3 mt-md-0 ">
            <ul className="list-unstyled">
              <li className="tamaño-medio text-center ">
                <Image
                  className="logo-mobile"
                  src={logofooter}
                  alt="logo footer"
                  fluid
                />
              </li>
            </ul>
          </div>
          {/* Column2 */}
          <div className="col-12 col-lg-4 text-md-start d-flex justify-content-center mx-auto mt-3 ">
            <ul className="list-unstyled peso-500-lato texto-size-18px centrado-mobile">
              <li className="my-4">
                <Nav.Link href="/">Home</Nav.Link>
              </li>
              <li className="my-4">
                <Nav.Link href="#experiencia">Experiencia</Nav.Link>
              </li>
              <li className="my-4">
                <Nav.Link href="#proposito">Propósito</Nav.Link>
              </li>
              <li className="my-4">
                <Nav.Link href="#servicios">Servicios</Nav.Link>
              </li>
            </ul>
          </div>
          {/* Column3 */}
          <div className="col-12 col-lg-4  text-md-start d-flex justify-content-center justify-content-lg-end mb-1 mt-2 p-1 ">
            <ul className="list-unstyled">
              <li>
                <div className="d-flex">
                  <div className="me-2">
                    <Image className="" src={icono1} alt="logo footer" fluid />
                  </div>
                  <p>
                    <span className="peso-500-lato texto-size-20px">
                      {" "}
                      Email{" "}
                    </span>
                    <br />
                    <span className="peso-400-lato texto-size-18px">
                      {" "}
                      mentor@sebaspinelli.com
                    </span>
                  </p>
                </div>
              </li>
              <li>
                {" "}
                <div className="d-flex">
                  <div className="me-2">
                    <Image className="" src={icono2} alt="logo footer" fluid />
                  </div>
                  <div className="">
                    <p>
                      <span className="peso-500-lato texto-size-20px">
                        {" "}
                        Teléfono{" "}
                      </span>
                      <br />
                      <span className="peso-400-lato texto-size-18px">
                        {" "}
                        11 4048 8900
                      </span>
                    </p>
                  </div>
                </div>
              </li>
              <li>
                {" "}
                <div className="d-flex">
                  <div className="me-2">
                    <a
                      className="text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/sebaspinelli.trainer/"
                    >
                      <Image
                        className=""
                        src={icono3}
                        alt="logo footer"
                        fluid
                      />
                    </a>
                  </div>
                  <p>
                    <a
                      className="text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/sebaspinelli.trainer/"
                    >
                      <span className="peso-500-lato texto-size-20px">
                        {" "}
                        Instagram
                      </span>
                      <br />
                      <span className="peso-400-lato texto-size-18px">
                        {" "}
                        @sebaspinelli.trainer
                      </span>
                    </a>
                  </p>
                </div>
              </li>
              <li>
                {" "}
                <div className="d-flex">
                  <div className="me-2">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                      href="https://www.linkedin.com/in/sebastian-spinelli-30031813/"
                    >
                      <Image
                        className=""
                        src={icono4}
                        alt="logo footer"
                        fluid
                      />
                    </a>
                  </div>
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                      href="https://www.linkedin.com/in/sebastian-spinelli-30031813/"
                    >
                      <span className="peso-500-lato texto-size-20px">
                        {" "}
                        LinkedIn
                      </span>
                      <br />
                      <span className="peso-400-lato texto-size-18px">
                        {" "}
                        @Sebastian Spinelli
                      </span>
                    </a>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
