import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Image } from "react-bootstrap";
import foto1 from "../assets/foto1.png";
import foto2 from "../assets/foto2.png";
import foto3 from "../assets/foto3.png";

// import required modules
import { Pagination } from "swiper";

export default function GaleriaSliderMobile() {
  return (
    <div>
      {" "}
      <>
        <Swiper
          slidesPerView={1}
          spaceBetween={-30}
          modules={[Pagination]}
          pagination={true}
          className="mySwiper pb-5"
        >
          <SwiperSlide>
            {" "}
            <Image
              className="galeria-foto-mobile"
              src={foto1}
              alt="Icono Servicio #2"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image
              className="galeria-foto-mobile"
              src={foto2}
              alt="Icono Servicio #2"
              fluid
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image
              className="galeria-foto-mobile"
              src={foto3}
              alt="Icono Servicio #2"
              fluid
            />
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
}
