import React from "react";
import { Button } from "react-bootstrap";

export default function BtnContacto() {
  return (
    <div className="py-4">
      <Button className="btn-contacto border-0 ">
        <a
          className="text-decoration-none peso-700-lato text-white "
          href="#footer"
        >
          CONTACTO
        </a>
      </Button>
    </div>
  );
}
