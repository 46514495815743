import React from "react";
import BtnContacto from "./BtnContacto";

export default function PropositoMobile() {
  return (
    <div className="container ">
      <div className="text-center my-4">
        <p className="peso-800-lato texto-size-18px  color-celeste">
          {" "}
          MI PROPÓSITO
        </p>
      </div>
      <div>
        <p className="text-center peso-700-lato texto-size-24px pb-2">
          Enfocar mis ideas, habilidades y herramientas,
        </p>
        <p className="text-center peso-300-redrose texto-size-20px ">
          para que personas y organizaciones, dejen de ir en su contra… <br />
          mejorando su bienestar mental, emocional y profesional.
        </p>
      </div>
      <div className="text-center py-4">
        <BtnContacto />
      </div>
    </div>
  );
}
