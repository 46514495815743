import React from "react";
import BtnContacto from "./BtnContacto";

export default function Proposito() {
  return (
    <div className="container my-5 py-5">
      <div className="d-flex justify-content-end">
        <div>
          <p className="peso-800-lato texto-size-18px me-4 color-celeste">
            {" "}
            MI PROPÓSITO
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-end me-4">
        <p>
          <span className="peso-400-redrose  texto-size-40px">
            Enfocar mis ideas, habilidades y herramientas, <br />
          </span>
          <div className="d-flex justify-content-end">
            <span className="peso-500-montserrat texto-size-30px ">
              para que personas y organizaciones, dejen de ir en su contra...
              <br /> <span className="me-5"></span> mejorando su bienestar
              mental, emocional y profesional.
              <br />{" "}
            </span>
          </div>
        </p>
      </div>
      <div className="d-flex justify-content-end me-4">
        <BtnContacto />
      </div>
    </div>
  );
}
