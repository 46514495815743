import React from "react";
import GaleriaSliderMobile from "./GaleriaSliderMobile";

export default function GaleriaMobile() {
  return (
    <div>
      <div className="text-center py-5 pb-2 peso-800-lato texto-size-18px color-celeste">
        <p>GALERÍA</p>
      </div>
      <div className="text-center pb-2 peso-400-redrose texto-size-24px">
        <p>Trabajos realizados</p>
      </div>
      <div>
        <GaleriaSliderMobile />
      </div>
    </div>
  );
}
