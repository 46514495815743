import React from "react";
import { Image } from "react-bootstrap";
import portada from "../assets/sebasportada.png";
import BtnContacto from "./BtnContacto";

export default function Experiencia() {
  return (
    <div className="container mt-4 pt-4">
      <div className="d-flex justify-content-start">
        <p className="peso-800-lato color-celeste texto-size-18px">
          {" "}
          MI EXPERIENCIA
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-sm-8">
          <p className="peso-400-redrose texto-size-42px">
            {" "}
            Especializado en resolver <br /> conflictos y generar <br />{" "}
            experiencias de marketing de <br /> alto impacto
          </p>
          <div>
            <div>
              <p className="peso-500-monserrat text-size-18px d-none d-lg-block">
                Constructor publicitario realizador de experiencias de marketing
                y <br />
                entretenimiento de alto impacto, con más de 25 años creando y{" "}
                <br />
                construyendo activaciones promocionales a pequeña y gran escala.{" "}
                <br />
                Master en Psicología aplicada a la persuasión y resolución de{" "}
                <br />
                conflictos personales y comerciales.
              </p>
              <p className="peso-500-monserrat text-size-18px d-lg-none d-block">
                Constructor publicitario realizador de experiencias de marketing
                y entretenimiento de alto impacto, con más de 25 años creando y
                construyendo activaciones promocionales a pequeña y gran escala.
                Master en Psicología aplicada a la persuasión y resolución de
                conflictos personales y comerciales.
              </p>
            </div>
          </div>

          <BtnContacto />
        </div>
        <div className="col-12 col-sm-4">
          <Image
            className="portada-sebas"
            src={portada}
            alt="portada sebas"
            fluid
          />
        </div>
      </div>
    </div>
  );
}
