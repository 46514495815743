import React from "react";
import { Image } from "react-bootstrap";
import titulobanner from "../assets/titulobanner.png";

export default function BannerSebas() {
  return (
    <div className="text-center mt-4">
      <div className=" pt-4 pb-2">
        {" "}
        <Image
          className="titulo-banner"
          src={titulobanner}
          alt="titulo banner"
          fluid
        />
      </div>
      <p className="peso-400-redrose texto-size-30px">
        High impact marketing and entrenament + Personal Business Mentor
      </p>
      <p className="peso-400-lato texto-size-20px ">@sebaspinelli.trainer</p>
    </div>
  );
}
