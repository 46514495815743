import React, { useState } from "react";
import { Image } from "react-bootstrap";
import icono1 from "../assets/iconoservicio1.png";
import icono2 from "../assets/iconoservicio2.png";
import puntoAzul from "../assets/puntoazul.png";
import Collapse from "react-bootstrap/Collapse";

export default function Servicios() {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-servicios">
      <div className="container py-4">
        <div>
          <div className="d-flex justify-content-start mt-4 ">
            <p className="peso-800-lato texto-size-18px color-celeste titulo-alineado">
              {" "}
              MIS SERVICIOS
            </p>
          </div>
        </div>

        {/* #1 */}
        <div className="d-flex justify-content-center ">
          <div className="card caja-servicios py-4 me-4">
            <div className="text-center py-1">
              <Image className="" src={icono1} alt="Icono Servicio #1" fluid />
            </div>
            <div>
              <p className="text-center peso-700-lato texto-size-24px pb-2">
                Consultoria Personal y comercial
              </p>
              <p className="ms-5 peso-500-montserrat texto-size-18px">
                <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                Transformación personal
              </p>
              <p className="ms-5 peso-500-montserrat texto-size-18px">
                {" "}
                <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                Capacitación comercial para profesional
              </p>
              <p className="ms-5 peso-500-montserrat texto-size-18px">
                {" "}
                <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                Sesiones individuales
              </p>
            </div>
            <div>
              <div className="d-flex justify-content-center">
                <p
                  className="peso-400-redrose  texto-size-18px"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  Speakers & Worshops +
                </p>
              </div>

              <Collapse in={open}>
                <div id="example-collapse-text">
                  <p className="ms-5 peso-500-montserrat texto-size-18px">
                    {" "}
                    <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                    Entrenamientos Comerciales y motivacionales dirigido a
                    Lideres de compañías y equipos de trabajo orientados a
                    resultados
                  </p>
                </div>
              </Collapse>
            </div>
          </div>

          {/* #2 */}
          <div className="d-flex justify-content-center ">
            <div className="card caja-servicios py-4 me-4">
              <div className="text-center py-1">
                <Image
                  className=""
                  src={icono2}
                  alt="Icono Servicio #1"
                  fluid
                />
              </div>
              <div>
                <p className="text-center peso-700-lato texto-size-24px pb-2">
                  Realizaciones Publicitarias
                </p>
                <p className="ms-5 peso-500-montserrat texto-size-18px">
                  <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                  Creatividad & Diseño
                </p>
                <p className="ms-5 peso-500-montserrat texto-size-18px">
                  {" "}
                  <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                  Construcciones Especiales
                </p>
                <p className="ms-5 peso-500-montserrat texto-size-18px">
                  {" "}
                  <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                  Tecnologías Interactivas
                </p>
                <p className="ms-5 peso-500-montserrat texto-size-18px">
                  {" "}
                  <Image className="me-2" src={puntoAzul} alt="Icono" fluid />
                  Experiencias de marketing de alto impacto emocional
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
