import React from "react";
import { Image } from "react-bootstrap";
import icono1 from "../assets/unicenter.png";
import icono2 from "../assets/altopalermo.png";
import icono3 from "../assets/adidas.png";
import icono4 from "../assets/cirque.png";
import icono5 from "../assets/viaviva.png";

export default function Marcas() {
  return (
    <div className="container">
      <div className="text-center mt-5">
        <h1 className="peso-400-redrose texto-size-30px">
          Algunas de las marcas que confian en mi{" "}
        </h1>
      </div>
      <div className="py-5">
        <div className="d-flex flex-wrap justify-content-center">
          <div>
            <div className="">
              <Image className="iconos-marcas mx-4" src={icono1} alt="icono" />
            </div>
          </div>
          <div>
            <div className="">
              <Image className="iconos-marcas mx-4" src={icono2} alt="icono" />
            </div>
          </div>
          <div>
            <div className="">
              <Image className="iconos-marcas mx-4" src={icono3} alt="icono" />
            </div>
          </div>
          <div>
            <div className="">
              <Image className="iconos-marcas mx-4" src={icono4} alt="icono" />
            </div>
          </div>
          <div>
            <div className="">
              <Image className="iconos-marcas mx-4" src={icono5} alt="icono" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
