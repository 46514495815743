import React from "react";
import { Image } from "react-bootstrap";
import foto1 from "../assets/foto1.png";
import foto2 from "../assets/foto2.png";
import foto3 from "../assets/foto3.png";

export default function Fotos() {
  return (
    <div className="container">
      <div className="d-flex justify-content-around">
        <div>
          <Image className="" src={foto1} alt="Icono Servicio #2" fluid />
        </div>
        <div>
          <Image className="" src={foto2} alt="Icono Servicio #2" fluid />
        </div>
        <div>
          <Image className="" src={foto3} alt="Icono Servicio #2" fluid />
        </div>
      </div>
    </div>
  );
}
