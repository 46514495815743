import { Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import navLogo from "../assets/navbarlogo.png";

export default function NavReact() {
  return (
    <Navbar className="color-blanco-fondo" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <Image className="logo-navbar" src={navLogo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="margen-link-nav  ">
            <Nav.Link className="me-4 peso-500-lato texto-size-18px " href="/">
              Home
            </Nav.Link>
            <Nav.Link
              className="me-4 peso-500-lato texto-size-18px"
              href="#experiencia"
            >
              Experiencia
            </Nav.Link>
            <Nav.Link
              className="me-4 peso-500-lato texto-size-18px"
              href="#proposito"
            >
              Propósito
            </Nav.Link>
            <Nav.Link
              className="me-4 peso-500-lato texto-size-18px"
              href="#servicios"
            >
              Servicios
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
