import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Image } from "react-bootstrap";
import icono1 from "../assets/unicenter.png";
import icono2 from "../assets/altopalermo.png";
import icono3 from "../assets/adidas.png";
import icono4 from "../assets/cirque.png";
import icono5 from "../assets/viaviva.png";

// import required modules
import { Pagination } from "swiper";

export default function MarcasMobile() {
  return (
    <div>
      <div className="text-center mt-5">
        <h1 className="peso-400-redrose texto-size-20px">
          Algunas de las marcas que <br /> confían en mi{" "}
        </h1>
      </div>
      <>
        <Swiper
          slidesPerView={2}
          spaceBetween={60}
          modules={[Pagination]}
          pagination={true}
          className="mySwiper"
        >
          <SwiperSlide>
            {" "}
            <Image className="iconos-marcas" src={icono1} alt="icono" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image className="iconos-marcas" src={icono2} alt="icono" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image className="iconos-marcas" src={icono3} alt="icono" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image className="iconos-marcas" src={icono4} alt="icono" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Image className="iconos-marcas mx-4" src={icono5} alt="icono" />
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
}
